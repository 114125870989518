import { Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Frame from '../Assets/Images/Login_Frame.webp'
import YapiRadarIcon from '../Assets/Icons/YapiRadar_White.png'
import DotIcon from '../Assets/Icons/LoginDotIcon.png'
import { useEffect } from 'react'
import { postData } from '../Assets/Scripts/dataAxios'
import { ShowMessage } from '../Components/Common/Alerts'
import { Link, useNavigate } from 'react-router-dom'
import AydinlatmaMetni from './AydinlatmaMetni'
import Navbar from '../Components/Navbar'

function DenemeTalebi() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "28px",
            "lineHeight": "130%",
            "color": "#000000",
        },
        Header2: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "12px",
            "lineHeight": "130%",
            "color": "#889AA4",
        },
        mailtxtbx: {
            marginTop: '2em',
            "background": "#FFFFFF",
            width: '437px',
            [`& fieldset`]: {
                borderRadius: 20,
            },
        },
        passwordtxtbx: {
            width: '437px',
            marginTop: '1.5em',
            [`& fieldset`]: {
                borderRadius: 20,
            },
        },
        LoginBtn: {
            textTransform: "none",
            "gap": "10px",
            "width": "437px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            }
        },
        LoginWithGoogleBtn: {
            textTransform: "none",
            "boxSizing": "border-box",
            "width": "437px",
            "height": "63px",
            "background": "#F4F7F9",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px"
        },
        LoginWithGoogleText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "18px",
            "lineHeight": "120%",
            "color": "#303C43"
        },
        textOR: {
            "width": "17px",
            "height": "18px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#BCC7CE"
        },
        DemoLink: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#8FBFFA"
        },
        DemoText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#BCC7CE"
        },
        headerRight: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "40px",
            "lineHeight": "130%",
            "color": "#F4F7F9",
            width: '469px'
        },
        TedarikciWrapper: {
            "width": "213px",
            "height": "41px",
            "background": "#EDF2F6",
            "borderRadius": "12px"
        },
        formControlChx: {
            marginLeft: '1em',
            "width": "91px",
            "height": "18px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#303C43"
        },
        ModalStyle: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            height: '100%',
            width: '90%',
            p: 4,
            overflow: 'scroll',
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [saveButtonDisable, setSaveButtonDisable] = useState(false);

    const [firmaadi, Setfirmaadi] = useState(false)
    const [adsoyad, Setadsoyad] = useState(false)
    const [mail, Setmail] = useState(false)
    const [telefon, Settelefon] = useState(false)
    const [segment, Setsegment] = useState('Tedarikçi')
    const [aydinlatmaMetniCheck, SetaydinlatmaMetniCheck] = useState(false)
    const handleSubmit = async () => {
        if (!adsoyad) {
            ShowMessage('error', "Lütfen Adınzı ve Soyadınızı Giriniz.")
        }
        else if (!mail) {
            ShowMessage('error', "Lütfen Geçerli Bir Mail Adresi Giriniz.")
        }
        else if (!firmaadi) {
            ShowMessage('error', "Lütfen Firma Adınızı Giriniz.")
        }
        else if (!telefon) {
            ShowMessage('error', "Lütfen Geçerli Bir Telefon Numarası Giriniz.")
        }
        else if (!segment) {
            ShowMessage('error', "Lütfen Deneme Talebi Tipinizi Seçiniz.")
        }
        else if (!aydinlatmaMetniCheck) {
            ShowMessage('error', "Lütfen Aydınlatma Metni'ni Okuyup İşaretleyiniz.")
        } else {
            const reqData = {
                firmaadi: firmaadi,
                adsoyad: adsoyad,
                mail: mail,
                telefon: telefon,
                segment: segment,
                formid: 0,
            }
            setSaveButtonDisable(true);
            const res = await postData('Landing/DemoKayit', reqData);
            if (res.data.isSucceeded) {
                setSaveButtonDisable(false);
                navigate('/DemoBasvurunuzAlindi')
            } else {
                ShowMessage('error', "Demo Başvurunuz Kayıt Edilirken Bir Hata Oluştu. Lüften internet bağlantınızı kontrol ediniz.")
                setSaveButtonDisable(false);
            }
        }
    }

    const navigate = useNavigate();
    const handleNavigateHome = () => {
        navigate('/')
    }


    const [tedarikciChx, SetTedarikciChx] = useState(true);
    const [muteahhitChx, SetMuteahhitChx] = useState(false);


    const handleSegmentCheck = (value) => {
        if (value === 'Müteahhit') {
            Setsegment('Müteahhit')
            SetMuteahhitChx(true);
            SetTedarikciChx(false);
        }
        if (value === 'Tedarikçi') {
            Setsegment('Tedarikçi')
            SetMuteahhitChx(false);
            SetTedarikciChx(true);
        }
    }

    return (
        <>
            <Navbar />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.ModalStyle}>
                    <AydinlatmaMetni isShowNavbarFooter={false} />
                    <Box
                        sx={{ background: '#F4F7F9', justifyContent: 'right', display: 'flex' }}
                    >
                        <Button
                            sx={{

                            }}
                            onClick={handleClose}
                        >Kapat</Button>
                    </Box>
                </Box>

            </Modal>

            <Grid
                sx={{
                    display: { xs: 'none', md: 'flex' }
                }}
                height='99vh' pt={12} container spacing={0}>
                <Grid pt={3} pl={3} sx={{ backgroundColor: '#FF6600' }} item xl={6} md={6} xs={6}>
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={handleNavigateHome}>
                        <img src={YapiRadarIcon} alt='yapiradar.com' />
                    </Box>
                    <Box ml={6} mt={5} sx={{}}>
                        <Typography sx={styles.headerRight}>
                            İnşaat sektörünün veri bankasına hoşgeldiniz
                        </Typography>
                    </Box>
                    <Box ml={6} mt={3}>
                        <img src={DotIcon} alt='yapiradar.com Giris Yap' />
                    </Box>
                    <Box ml={25} mt={3}>
                        <img src={Frame} alt='yapiradar.com Login' />
                    </Box>
                </Grid>
                <Grid pt={10} align='center' item xl={6} md={6} xs={6}>
                    <Card sx={{ minHeight: '100%' }}>
                        <CardContent align='left' sx={{ width: '30vw' }}>
                            <Typography sx={styles.Header1}>
                                Deneme Talebi Oluştur
                            </Typography>
                            <Typography sx={styles.Header2}>
                                Talebinizi oluşturmak ve projeleri incelemek için lütfen aşağıdaki formu doldurunuz.
                            </Typography>
                            <Box>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    required
                                    onChange={e => Setadsoyad(e.target.value)}
                                    label="Ad Soyad"
                                    sx={styles.mailtxtbx}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => Setmail(e.target.value)}
                                    label="Email"
                                    sx={styles.passwordtxtbx}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    type="mail"
                                    onChange={e => Setfirmaadi(e.target.value)}
                                    label="Firma Adı"
                                    sx={styles.mailtxtbx}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => Settelefon(e.target.value)}
                                    label="Telefon"
                                    sx={styles.mailtxtbx}
                                />
                            </Box>
                            <Box display='flex' mt='1em'>
                                <Box mr={1} sx={styles.TedarikciWrapper}>
                                    <FormControlLabel
                                        sx={{ display: 'flex' }}
                                        control={
                                            <Checkbox
                                                sx={{ marginLeft: '1em', float: 'right' }}
                                                name='tedarikciChx'
                                                value='Tedarikçi'
                                                checked={tedarikciChx}
                                                onChange={() => handleSegmentCheck('Tedarikçi')}
                                            />
                                        }
                                        label={<Typography sx={styles.formControlChx}>Tedarikçiyim</Typography>}
                                    />
                                </Box>
                                <Box sx={styles.TedarikciWrapper}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{ marginLeft: '1em', float: 'right' }}
                                                name='Müteahhit'
                                                value='Müteahhit'
                                                checked={muteahhitChx}
                                                onChange={() => handleSegmentCheck('Müteahhit')}
                                            />
                                        }
                                        label={<Typography sx={styles.formControlChx}>Müteahhitim</Typography>}
                                    />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <FormControlLabel
                                    sx={{ color: '#BCC7CE' }}
                                    control={
                                        <Checkbox
                                            onChange={e => SetaydinlatmaMetniCheck(e.target.value)}
                                            name='Aydinlatma Metni'
                                            value={aydinlatmaMetniCheck ? false : true}
                                        />
                                    }
                                    label={
                                        <div>
                                            <Link style={{ textDecoration: 'none' }} onClick={handleOpen}>
                                                <Typography sx={{
                                                    textDecoration: 'none',
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "lineHeight": "130%",
                                                    "color": "#8FBFFA"
                                                }}>
                                                    Aydınlatma Metni
                                                </Typography>
                                            </Link>
                                            <span>’ni okudum ve kabul ediyorum. </span>
                                        </div>
                                    }
                                />
                            </Box>
                            <Box mt='2em'>
                                <Button disabled={saveButtonDisable} sx={styles.LoginBtn}
                                    onClick={handleSubmit}
                                > Kayıt Ol </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>



            <Grid
                sx={{
                    display: { xs: 'flex', md: 'none' }
                }}
                maxHeight='99vh' container spacing={0}>
                <Grid pt={10} align='center' item xl={12} md={12} xs={12}>
                    <Card sx={{ minHeight: '100%', border: 'none', boxShadow: 'none' }}>
                        <CardContent align='center'>
                            <Typography sx={styles.Header1}>
                                Deneme Talebi Oluştur
                            </Typography>
                            <Typography sx={styles.Header2}>
                                Talebinizi oluşturmak ve projeleri incelemek için lütfen aşağıdaki formu doldurunuz.
                            </Typography>
                            <Box>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    required
                                    onChange={e => Setadsoyad(e.target.value)}
                                    label="Ad Soyad"
                                    sx={{
                                        marginTop: '2em',
                                        "background": "#FFFFFF",
                                        width: '80vw',
                                        [`& fieldset`]: {
                                            borderRadius: 20,
                                        }
                                    }}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => Setmail(e.target.value)}
                                    label="Email"
                                    sx={{
                                        marginTop: '2em',
                                        "background": "#FFFFFF",
                                        width: '80vw',
                                        [`& fieldset`]: {
                                            borderRadius: 20,
                                        }
                                    }}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    type="mail"
                                    onChange={e => Setfirmaadi(e.target.value)}
                                    label="Firma Adı"
                                    sx={{
                                        marginTop: '2em',
                                        "background": "#FFFFFF",
                                        width: '80vw',
                                        [`& fieldset`]: {
                                            borderRadius: 20,
                                        }
                                    }}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    required
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => Settelefon(e.target.value)}
                                    label="Telefon"
                                    sx={{
                                        marginTop: '2em',
                                        "background": "#FFFFFF",
                                        width: '80vw',
                                        [`& fieldset`]: {
                                            borderRadius: 20,
                                        }
                                    }}
                                />
                            </Box>
                            <Box display='block' justifyContent='center' mt='1em'>
                                <Box mt={2} sx={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    "width": "80vw",
                                    "height": "41px",
                                    "background": "#EDF2F6",
                                    "borderRadius": "12px"
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{ marginLeft: '1em', float: 'right' }}
                                                name='tedarikciChx'
                                                value='Tedarikçi'
                                                checked={tedarikciChx}
                                                onChange={() => handleSegmentCheck('Tedarikçi')}
                                            />
                                        }
                                        label={<Typography sx={{
                                            "width": "91px",
                                            "height": "18px",
                                            "fontFamily": "'Poppins'",
                                            "fontStyle": "normal",
                                            "fontWeight": "500",
                                            "fontSize": "14px",
                                            "lineHeight": "130%",
                                            "color": "#303C43"
                                        }}>Tedarikçiyim</Typography>}
                                    />
                                </Box>
                                <Box mt={2} sx={{
                                    "width": "80vw",
                                    "height": "41px",
                                    "background": "#EDF2F6",
                                    "borderRadius": "12px"
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                sx={{ marginLeft: '1em', float: 'right' }}
                                                name='Müteahhit'
                                                value='Müteahhit'
                                                checked={muteahhitChx}
                                                onChange={() => handleSegmentCheck('Müteahhit')}
                                            />
                                        }
                                        label={<Typography sx={{
                                            "width": "91px",
                                            "height": "18px",
                                            "fontFamily": "'Poppins'",
                                            "fontStyle": "normal",
                                            "fontWeight": "500",
                                            "fontSize": "14px",
                                            "lineHeight": "130%",
                                            "color": "#303C43"
                                        }}>Müteahhitim</Typography>}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: '80vw' }} mt={2}>
                                <FormControlLabel
                                    sx={{ color: '#BCC7CE', display: 'flex' }}
                                    control={
                                        <Checkbox
                                            onChange={e => SetaydinlatmaMetniCheck(e.target.value)}
                                            name='Aydinlatma Metni'
                                            value={aydinlatmaMetniCheck ? false : true}
                                        />
                                    }
                                    label={
                                        <div>
                                            <Link style={{ textDecoration: 'none' }} onClick={handleOpen}>
                                                <Typography sx={{
                                                    textDecoration: 'none',
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "color": "#8FBFFA"
                                                }}>
                                                    Aydınlatma Metni
                                                </Typography>
                                            </Link>
                                            <span>’ni okudum ve kabul ediyorum. </span>
                                        </div>
                                    }
                                />
                            </Box>
                            <Box mt='2em'>
                                <Button disabled={saveButtonDisable} sx={{
                                    textTransform: "none",
                                    "gap": "10px",
                                    "width": "80vw",
                                    "height": "63px",
                                    "background": "#FF6600",
                                    "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
                                    "borderRadius": "20px",
                                    color: 'white',
                                    ':hover': {
                                        bgcolor: '#FF6600',
                                        boxShadow: 'none',
                                    }
                                }}
                                    onClick={handleSubmit}
                                > Kayıt Ol </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default DenemeTalebi