import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { Box, Button, Grid, Typography, TextField, Card, CardContent, IconButton, Divider, Container, FormControlLabel, Checkbox, TableCell, TableContainer, Table, TableHead, TableRow, TableBody, Paper, Dialog, DialogTitle, DialogContent, DialogActions, CardActions } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { keyframes } from '@emotion/react';
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { loadState } from '../../Assets/Scripts/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { ShowMessage } from '../../Components/Common/Alerts';


const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;



function KrediSatinal() {
    const [cart, setCart] = useState([]);
    const [showCreditForm, setShowCreditForm] = useState(false);
    const [showContract, setShowContract] = useState(false);
    const [packages, setPackages] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contractAgreed, setContractAgreed] = useState(false); // Sözleşme onayı state'i
    const [selectedOrderId, setSelectedOrderId] = useState(0);
    const [popupHtml, setPopupHtml] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [activePaymentId, setActivePaymentId] = useState(0);
    const [messageHistory, setMessageHistory] = useState([]);
    const [lastMssge, setLastMssge] = useState([]);
    const [bankInterestRateList, setBankInterestRateList] = useState([]);
    const [cardList, setCardList] = useState([]);

    const [purchaseForm, setPurchaseForm] = useState({
        "orderId": selectedOrderId,
        "creditCardName": "",
        "creditCardNumber": "",
        "creditCardExpireYear": '',
        "creditCardExpireMonth": '',
        "creditCardCvv2": "",
        "installmentId": 0
    });

    const UserData = JSON.parse(loadState('User'));
    const AuthToken = UserData.AuthKey ? UserData.AuthKey : '';
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | Kredi Al';
        if (!UserData) {
            navigate('/')
        }
    }, []);

    const socketUrl = `wss://testapi.yapiradar.com/CheckoutHub?ActivePaymentId=${activePaymentId}&Authorization=${AuthToken}`;
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        share: false,
        shouldReconnect: () => false,
    });
    const handleClickSendMessage = useCallback(() => sendMessage('{"protocol":"json","version":1}'), []);
    useEffect(() => {
        if (lastMessage !== null) {
            try {
                const lastMssg = JSON.parse(lastMessage.data.slice(0, -1));
                console.log(lastMssg);
                if (lastMssg?.arguments && lastMssg.arguments.length > 0 && lastMssg.arguments[0]?.isSuccessed === false) {
                    setIsPopupOpen(false);
                    ShowMessage('error', lastMssg.arguments[0]?.message);
                }
            } catch (error) {
                console.error('An error occurred while processing the message:', error);
            }
        }
    }, [lastMessage, setMessageHistory]);


    const handlePurchase = async () => {
        const data = purchaseForm;
        try {
            const response = await postData('Checkout/Purchase', data);
            if (response.data.isSucceeded) {
                setPopupHtml(response.data.value.webHtml);
                setActivePaymentId(response.data.value?.paymentId)
                setIsPopupOpen(true);
                handleClickSendMessage();
            } else {
                console.error('Purchase failed:', response.data.error);
            };
        } catch (error) {
            console.error('Error making purchase:', error);
        }
    };


    const handleAddToCart = (packageId) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(item => item.id === packageId);
            if (existingItem) {
                return prevCart.map(item =>
                    item.id === packageId ? { ...item, quantity: item.quantity + 1 } : item
                );
            } else {
                return [...prevCart, { id: packageId, quantity: 1 }];
            }
        });
    };

    const handleRemoveFromCart = (packageId) => {
        setCart(prevCart => {
            const existingItem = prevCart.find(item => item.id === packageId);
            if (existingItem.quantity === 1) {
                return prevCart.filter(item => item.id !== packageId);
            } else {
                return prevCart.map(item =>
                    item.id === packageId ? { ...item, quantity: item.quantity - 1 } : item
                );
            }
        });
    };

    const handleContinueToContract = () => {
        setShowContract(true);
    };

    const handleContractAgreement = () => {
        setContractAgreed(!contractAgreed);
    };

    const goToPurchase = async () => {
        const packageIds = cart.flatMap(item => Array(item.quantity).fill(item.id));
        const requestData = {
            packageIds,
            deletePackageIds: [],
            existOrderId: 0
        };

        try {
            const response = await postData('Checkout/InOrder', requestData);
            if (response?.status === 200) {
                setPurchaseForm(prevState => ({
                    ...prevState,
                    orderId: response.data.value
                }));
                setSelectedOrderId(response.data.value)
                // Fetch updated order list
                const orderResponse = await getData('Checkout/OrderList');
                const orderList = orderResponse?.data?.value || [];
                setOrderList(orderList);

                // Update state after fetching the order list
                setShowContract(false);
                setShowCreditForm(true);
            } else {
                console.error('Checkout/InOrder request failed.');
            }
        } catch (error) {
            console.error('An error occurred while making the request:', error);
        }
    };

    const handleBack = () => {
        setShowContract(false);
    };

    const handleOrderClick = async (orderId) => {
        try {
            setPurchaseForm(prevState => ({
                ...prevState,
                orderId: parseInt(orderId, 10)
            }))
            setSelectedOrderId(parseInt(orderId, 10))
            setShowCreditForm(true);
        } catch (error) {
            console.error('An error occurred while making the request:', error);
        }
    };

    const totalAmount = cart.reduce((total, item) => {
        const pkg = packages.find(pkg => pkg.id === item.id);
        return total + (pkg ? pkg.moneyAmount * item.quantity : 0);
    }, 0);

    const totalCredits = cart.reduce((total, item) => {
        const pkg = packages.find(pkg => pkg.id === item.id);
        return total + (pkg ? pkg.coinAmount * item.quantity : 0);
    }, 0);

    // Hooks
    useEffect(() => {
        const fetchData = async () => {
            try {
                const packageResponse = await getData('Checkout/PackageList');
                const packageList = packageResponse?.data?.value || [];
                setPackages(packageList);
                const orderResponse = await getData('Checkout/OrderList');
                const orderList = orderResponse?.data?.value || [];
                setOrderList(orderList);

                const res1 = await getData('Checkout/BankInterestRateList');
                const bankInterests = res1?.data?.value || [];
                setBankInterestRateList(bankInterests);

                const res2 = await getData('Checkout/CardList');
                const cardLists = res2?.data?.value || [];
                setCardList(cardLists);

                setLoading(false);
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const installmentPlans = [
        { bank: 'Türkiye İş Bankası', installment: '3 Ay', rate: '1.10%' },
        { bank: 'Türkiye İş Bankası', installment: '6 Ay', rate: '3.15%' },
        { bank: 'Türkiye İş Bankası', installment: '9 Ay', rate: '6.20%' },
        { bank: 'Türkiye İş Bankası', installment: '12 Ay', rate: '11.25%' },
        { bank: 'Garanti BBVA', installment: '3 Ay', rate: '1.05%' },
        { bank: 'Garanti BBVA', installment: '6 Ay', rate: '1.10%' },
        { bank: 'Garanti BBVA', installment: '9 Ay', rate: '1.15%' },
        { bank: 'Garanti BBVA', installment: '12 Ay', rate: '1.20%' },
        { bank: 'Denizbank', installment: '3 Ay', rate: '1.12%' },
        { bank: 'Denizbank', installment: '6 Ay', rate: '1.18%' },
        { bank: 'Denizbank', installment: '9 Ay', rate: '1.22%' },
        { bank: 'Denizbank', installment: '12 Ay', rate: '1.28%' },
        { bank: 'Halkbank', installment: '3 Ay', rate: '1.08%' },
        { bank: 'Halkbank', installment: '6 Ay', rate: '1.13%' },
        { bank: 'Halkbank', installment: '9 Ay', rate: '1.17%' },
        { bank: 'Halkbank', installment: '12 Ay', rate: '1.23%' },
    ];
    const banks = [...new Set(installmentPlans.map(plan => plan.bank))];


    const handleCreditCardNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length > 16) {
            value = value.slice(0, 16); // Limit to 16 digits
        }
        value = value.replace(/(.{4})/g, '$1 ').trim(); // Insert space every 4 digits
        setPurchaseForm(prevState => ({
            ...prevState,
            creditCardNumber: value
        }));
    };
    const handleCvvChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length > 3) {
            value = value.slice(0, 3); // Limit to 3 digits
        }
        setPurchaseForm(prevState => ({
            ...prevState,
            creditCardCvv2: value
        }));
    }
    const handleExpireMonthChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length > 2) {
            value = value.slice(0, 2); // Limit to 2 digits
        }
        setPurchaseForm(prevState => ({
            ...prevState,
            creditCardExpireMonth: value
        }));
    };

    const handleExpireYearChange = (e) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (value.length > 2) {
            value = value.slice(0, 2); // Limit to 2 digits
        }
        setPurchaseForm(prevState => ({
            ...prevState,
            creditCardExpireYear: value
        }));
    };

    return (
        <div>
            <Navbar />
            <Container mt={10} sx={{ marginTop: '5em' }} maxWidth="xl">
                {!showCreditForm && !showContract && orderList.length > 0 && (
                    <Box mt={2} p={4} bgcolor="#f5f5f5">
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h5" align="center" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                                    Bekleyen Siparişleriniz
                                </Typography>
                            </Grid>
                            {loading ? (
                                <Typography variant="body1" align="center" sx={{ fontFamily: 'Poppins' }}>
                                    Yükleniyor...
                                </Typography>
                            ) : orderList.length === 0 ? (
                                <Typography variant="body1" align="center" sx={{ fontFamily: 'Poppins' }}>
                                    Hiç sipariş bulunamadı.
                                </Typography>
                            ) : (
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Sipariş ID</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Paket Başlığı</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Adet</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Kredi</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Fiyatlar (₺)</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>Tutar (₺)</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>İşlemler</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderList.map(order => (
                                                    <TableRow
                                                        key={order.orderId}
                                                        onClick={() => handleOrderClick(order.orderId)}
                                                        sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' } }}
                                                    >
                                                        <TableCell>{order.orderId}</TableCell>
                                                        <TableCell>
                                                            {order.orderItems.map(item => (
                                                                <div key={item.id}>
                                                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.coinPackage.title}</Typography>
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>
                                                            {order.orderItems.map(item => (
                                                                <div key={item.id}>
                                                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>1</Typography>
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>
                                                            {order.orderItems.map(item => (
                                                                <div key={item.id}>
                                                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.coinAmount}</Typography>
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>
                                                            {order.orderItems.map(item => (
                                                                <div key={item.id}>
                                                                    <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>{item.moneyAmount} ₺</Typography>
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body2" color="text.primary" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                                                                Toplam Kredi: {order.orderItems.reduce((total, item) => total + item.coinAmount, 0)} Kredi
                                                            </Typography>
                                                            <Typography variant="body2" color="text.primary" gutterBottom sx={{ fontFamily: 'Poppins' }}>
                                                                Toplam Tutar: {order.orderItems.reduce((total, item) => total + item.moneyAmount, 0)} ₺
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button>Satın Al</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}
                {!showCreditForm && !showContract && (
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} md={12}>
                            <Typography sx={{
                                color: '#0A354E',
                                fontFamily: "'Poppins'",
                                fontWeight: 800,
                                fontSize: "28px",
                                lineHeight: "120%",
                            }} align="center">
                                E-İhale ve E-Satınalma Kredisi Satınal
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 400,
                                    fontSize: "18px",
                                    lineHeight: "140%",
                                    color: "#3C6780",
                                    textAlign: "center",
                                    marginBottom: "20px"
                                }}
                            >
                                Ne kadar kredi almak istediğinizi seçip ödeme ekranına geçebilirsiniz.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8} xl={8}>
                            <Grid container spacing={2} align="center">
                                {loading ? (
                                    <Typography sx={{ fontFamily: "'Poppins'", fontSize: "18px" }}>Yükleniyor...</Typography>
                                ) : (
                                    packages.map((pkg) => (
                                        <Grid key={pkg.id} item xs={12}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    padding: '1em',
                                                    background: 'white',
                                                    borderRadius: '16px',
                                                    cursor: 'pointer',
                                                    alignItems: 'center',
                                                    ":hover": {
                                                        background: '#FF6600',
                                                        color: 'white',
                                                        borderRadius: '16px'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <MonetizationOnIcon sx={{ fontSize: '40px' }} />
                                                </Box>
                                                <Box sx={{ width: '65%', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Typography sx={{
                                                        fontFamily: "'Poppins'",
                                                        fontWeight: 700,
                                                        fontSize: "16px",
                                                        lineHeight: "140%",
                                                    }}>
                                                        <span style={{ fontSize: '22px' }}>{pkg.coinAmount}</span> Kredi
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontFamily: "'Poppins'",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "140%",
                                                        textAlign: 'left',
                                                    }}>
                                                        Tüm E-İhale teklifleri ve E-Satınalma için geçerlidir.
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '15%', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Typography sx={{
                                                        fontFamily: "'Poppins'",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "140%",
                                                        textAlign: 'left',
                                                    }}>
                                                        <span style={{ fontSize: '16px' }}>{pkg.moneyAmount} ₺</span>
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <IconButton onClick={() => handleAddToCart(pkg.id)} color="primary">
                                                        <AddIcon />
                                                    </IconButton>
                                                    {cart.find(item => item.id === pkg.id) && (
                                                        <IconButton onClick={() => handleRemoveFromCart(pkg.id)} color="secondary">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <Box sx={{
                                padding: '1em',
                                background: '#F9FBFC',
                                borderRadius: '16px',
                                animation: `${fadeIn} 0.5s forwards`
                            }}>
                                <Typography sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 700,
                                    fontSize: "22px",
                                    lineHeight: "140%",
                                    marginBottom: '1em',
                                    textAlign: 'center'
                                }}>
                                    Sepet
                                </Typography>
                                {cart.length === 0 ? (
                                    <Typography sx={{
                                        fontFamily: "'Poppins'",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        lineHeight: "140%",
                                        textAlign: 'center'
                                    }}>
                                        Sepetinizde ürün yok.
                                    </Typography>
                                ) : (
                                    cart.map(item => {
                                        const pkg = packages.find(pkg => pkg.id === item.id);
                                        return (
                                            <Box key={item.id} mb={3} sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '1em',
                                                background: 'white',
                                                borderRadius: '16px'
                                            }}>
                                                <Typography sx={{
                                                    fontFamily: "'Poppins'",
                                                    fontWeight: 700,
                                                    fontSize: "16px",
                                                    lineHeight: "140%",
                                                }}>
                                                    {pkg.coinAmount} Kredi
                                                </Typography>
                                                <Typography sx={{
                                                    fontFamily: "'Poppins'",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    lineHeight: "140%",
                                                    textAlign: 'left',
                                                }}>
                                                    {item.quantity} x {pkg.moneyAmount} ₺
                                                </Typography>
                                            </Box>
                                        );
                                    })
                                )}
                                <Divider sx={{ my: 2 }} />
                                <Typography sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    lineHeight: "140%",
                                    textAlign: 'center',
                                    marginBottom: '1em'
                                }}>
                                    Toplam Kredi: {totalCredits}
                                </Typography>
                                <Typography sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    lineHeight: "140%",
                                    textAlign: 'center',
                                    marginBottom: '1em'
                                }}>
                                    Toplam Tutar: {totalAmount} ₺
                                </Typography>

                                <Button
                                    onClick={handleContinueToContract}
                                    endIcon={<ArrowRightAltIcon sx={{ color: 'white' }} />}
                                    sx={{
                                        width: '100%',
                                        padding: '0.5em 1em',
                                        background: "#FF6600",
                                        boxShadow: "0px 9px 22px rgba(34, 12, 0, 0.25)",
                                        borderRadius: "20px",
                                        ':hover': {
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#FF6600",
                                            boxShadow: "none",
                                            borderRadius: "20px",
                                        },
                                        marginTop: '20px'
                                    }}>
                                    <Typography sx={{
                                        fontFamily: "'Poppins'",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "140%",
                                        textAlign: 'left',
                                        color: 'white',
                                    }}>
                                        Devam Et
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>
                )}
                {showContract && (
                    <Grid container spacing={2} mt={5} sx={{ animation: `${fadeIn} 0.5s forwards` }}>
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }} mt={10} item xs={12}>
                            <Box sx={{
                                maxWidth: '800px',
                                padding: '1em',
                                background: '#F9FBFC',
                                borderRadius: '16px'
                            }}>
                                <Typography sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 700,
                                    fontSize: "22px",
                                    lineHeight: "140%",
                                    marginBottom: '1em',
                                    textAlign: 'center'
                                }}>
                                    Sözleşme Detayları
                                </Typography>
                                {cart.map(item => {
                                    const pkg = packages.find(pkg => pkg.id === item.id);
                                    return (
                                        <Box key={item.id} mb={3} sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}>
                                            <Typography sx={{
                                                fontFamily: "'Poppins'",
                                                fontWeight: 700,
                                                fontSize: "16px",
                                                lineHeight: "140%",
                                            }}>
                                                {pkg.coinAmount} Kredi
                                            </Typography>
                                            <Typography sx={{
                                                fontFamily: "'Poppins'",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "140%",
                                                textAlign: 'left',
                                            }}>
                                                {item.quantity} x {pkg.moneyAmount} ₺
                                            </Typography>
                                        </Box>
                                    );
                                })}
                                <Typography sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    lineHeight: "140%",
                                    marginBottom: '1em',
                                    textAlign: 'center'
                                }}>
                                    Toplam Kredi: {totalCredits}
                                </Typography>
                                <Typography sx={{
                                    fontFamily: "'Poppins'",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    lineHeight: "140%",
                                    marginBottom: '1em',
                                    textAlign: 'center'
                                }}>
                                    Toplam Tutar: {totalAmount} ₺
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox onChange={handleContractAgreement} />}
                                    label="Sözleşmeyi Onaylıyorum"
                                />
                                <Button
                                    disabled={!contractAgreed}
                                    onClick={goToPurchase}
                                    sx={{
                                        padding: '0.5em 1em',
                                        background: "#FF6600",
                                        boxShadow: "0px 9px 22px rgba(34, 12, 0, 0.25)",
                                        borderRadius: "20px",
                                        ':hover': {
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#FF6600",
                                            boxShadow: "none",
                                            borderRadius: "20px",
                                        },
                                        marginTop: '20px',
                                        width: '100%',
                                    }}>
                                    <Typography sx={{
                                        fontFamily: "'Poppins'",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "140%",
                                        textAlign: 'left',
                                        color: 'white',
                                    }}>
                                        Ödeme Adımına Geç
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {showCreditForm && (
                    <Grid container spacing={5} mt={12} mb={5} sx={{ animation: `${fadeIn} 0.5s forwards`, background: '#F9FBFC', width: '100%', padding: '1em' }}>
                        <Grid item xs={12} sm={6}>
                            {selectedOrderId && (
                                <Box sx={{ paddingY: '1em', background: '#F9FBFC', borderRadius: '16px' }}>
                                    <Card sx={{ marginBottom: '1em', borderRadius: '16px', boxShadow: 'none' }}>
                                        <CardContent>
                                            <Typography variant="body1" align="center" mt={2} sx={{ fontFamily: "'Poppins'" }}>
                                                <div style={{ marginBottom: '0.5em' }}>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Toplam Kredi:</Typography>
                                                    <Typography variant="body2">{orderList.find(order => order.orderId === selectedOrderId).totalCoinAmount}</Typography>
                                                </div>
                                                <div style={{ marginBottom: '0.5em' }}>
                                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Toplam Tutar:</Typography>
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{orderList.find(order => order.orderId === selectedOrderId)?.totalMoneyAmount} ₺</Typography>
                                                </div>
                                            </Typography>

                                            <Typography sx={{ fontFamily: "'Poppins'", fontWeight: 700, fontSize: "22px", lineHeight: "140%", marginTop: '1em', marginBottom: '1em', textAlign: 'center' }}>
                                                Kredi Kartı Bilgileri
                                            </Typography>

                                            <TextField
                                                fullWidth
                                                onChange={(e) => setPurchaseForm(prevState => ({
                                                    ...prevState,
                                                    creditCardName: e.target.value
                                                }))}
                                                label="Kart Üzerindeki İsim"
                                                variant="outlined"
                                                margin="normal"
                                                InputProps={{ sx: { borderRadius: '10px' } }}
                                                name="creditCardName"
                                            />
                                            <TextField
                                                fullWidth
                                                onChange={handleCreditCardNumberChange}
                                                placeholder='XXXX - XXXX - XXXX - XXXX'
                                                value={purchaseForm.creditCardNumber}
                                                label="Kart Numarası"
                                                name="creditCardNumber"
                                                variant="outlined"
                                                margin="normal"
                                                InputProps={{ sx: { borderRadius: '10px' } }}
                                            />
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        onChange={handleExpireMonthChange}
                                                        label="Son Kullanma Tarihi (Ay)"
                                                        name='creditCardExpireMonth'
                                                        variant="outlined"
                                                        margin="normal"
                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                        value={purchaseForm.creditCardExpireMonth}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        onChange={handleExpireYearChange}
                                                        label="Son Kullanma Tarihi (Yıl)"
                                                        variant="outlined"
                                                        margin="normal"
                                                        InputProps={{ sx: { borderRadius: '10px' } }}
                                                        value={purchaseForm.creditCardExpireYear}
                                                        name="creditCardExpireYear"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <TextField
                                                fullWidth
                                                onChange={handleCvvChange}
                                                label="CVV"
                                                variant="outlined"
                                                margin="normal"
                                                InputProps={{ sx: { borderRadius: '10px' } }}
                                                name="creditCardCvv2"
                                                value={purchaseForm.creditCardCvv2}
                                            />
                                            <TextField
                                                fullWidth
                                                select
                                                onChange={(e) => setPurchaseForm(prevState => ({
                                                    ...prevState,
                                                    installmentId: parseInt(e.target.value, 10)
                                                }))}
                                                label="Taksit Sayısı"
                                                variant="outlined"
                                                margin="normal"
                                                SelectProps={{ native: true }}
                                                InputProps={{ sx: { borderRadius: '10px' } }}
                                                name="installmentId"
                                            >
                                                <option value={1}>Tek Çekim</option>
                                                {[...Array(11)].map((_, index) => (
                                                    <option key={index + 1} value={index + 2}>{index + 2}</option>
                                                ))}
                                            </TextField>
                                        </CardContent>
                                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                                            <Button
                                                onClick={handlePurchase}
                                                sx={{
                                                    padding: '0.5em 1em',
                                                    background: "#FF6600",
                                                    boxShadow: "0px 9px 22px rgba(34, 12, 0, 0.25)",
                                                    borderRadius: "20px",
                                                    ':hover': {
                                                        background: "#FF6600",
                                                        boxShadow: "none",
                                                    }
                                                }}
                                            >
                                                <Typography sx={{ fontFamily: "'Poppins'", fontWeight: 500, fontSize: "14px", lineHeight: "140%", textAlign: 'left', color: 'white' }}>
                                                    Satın Al
                                                </Typography>
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Box>

                            )}
                        </Grid>
                        <Dialog
                            open={isPopupOpen}
                            onClose={() => setIsPopupOpen(false)}
                            maxWidth="lg"
                            fullWidth
                        >
                            <DialogTitle>Ödeme Doğrulama</DialogTitle>
                            <DialogContent dividers>
                                <iframe
                                    srcDoc={popupHtml.replace(/\\"/g, '"').replace(/\\'/g, "'").replace(/\\\\/g, '\\')}
                                    style={{ width: '100%', height: '600px', border: 'none' }}
                                    onLoad={(e) => {
                                        const iframe = e.target;
                                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                                        const form = iframeDoc.querySelector('form');
                                        if (form) form.submit();
                                    }}
                                />
                            </DialogContent>
                        </Dialog>

                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid container spacing={2}>
                                    <Typography sx={{
                                        fontFamily: "'Poppins'",
                                        fontWeight: 500,
                                        fontSize: "22px",
                                        lineHeight: "140%",
                                        textAlign: 'left',
                                        color: 'black',
                                    }}>
                                        Taksit bilgileri
                                    </Typography>
                                    <TableContainer component={Paper} sx={{ marginBottom: '1em', boxShadow: 'none' }}>
                                        <Table sx={{ '& td, & th': { padding: '8px' } }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Anlaşmalı Banka</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Paket Fiyatı</Typography>
                                                    </TableCell>
                                                    {bankInterestRateList.map((rate, rateIndex) => (
                                                        <TableCell key={rateIndex}>
                                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}> {rate.taksitsayisi} Taksit</Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cardList.map((card, cardIndex) => (
                                                    <TableRow key={cardIndex}>
                                                        <TableCell>
                                                            <img height='40px' src={`https://test.yapiradar.com/images/Banka/${card.imageUrl}`} alt={card.title} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {orderList
                                                                .filter(order => order.orderId === selectedOrderId) // Seçilen siparişi filtrele
                                                                .map(order => (
                                                                    order.orderItems.reduce((total, item) => total + item.moneyAmount, 0) // Toplam tutarı hesapla
                                                                ))}₺
                                                        </TableCell>
                                                        {bankInterestRateList.map((rate, rateIndex) => (
                                                            <TableCell key={rateIndex}>
                                                                {orderList
                                                                    .filter(order => order.orderId === selectedOrderId) // Seçilen siparişi filtrele
                                                                    .map(order => {
                                                                        const totalAmount = order.orderItems.reduce((total, item) => total + item.moneyAmount, 0); // Toplam tutarı hesapla
                                                                        const totalWithInterest = totalAmount * (1 + rate.vadefarki / 100); // Faiz oranını uygula
                                                                        return `${totalWithInterest.toFixed(2)}₺ (${rate.vadefarki}%)`;
                                                                    })}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>


                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Container>
            <Footer />
        </div>
    );
}

export default KrediSatinal;
