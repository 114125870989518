import React from 'react';

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export const ShowMessage = (type, messsage) => {
    if (type == 'success') {
        return (
            Swal.fire({
                title: 'Başarılı!',
                text: messsage,
                icon: 'success',
                confirmButtonText: 'Tamam'
            })
        )
    }
    if (type == 'error') {
        return (
            Swal.fire({
                title: 'Hata!',
                text: messsage,
                icon: 'error',
                confirmButtonText: 'Tamam'
            })
        )
    }
}
