import { createSlice } from '@reduxjs/toolkit'
import { loadState, saveState } from '../../Assets/Scripts/LocalStorage';



const userInfo = loadState('UserInfo');
if (!userInfo) saveState('UserInfo', false)

const initialState = {
    userInfo,
}

export const UserInfoSlice = createSlice({
    name: 'UserInfo',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            const userJWTinfo = loadState('User');
            if (userJWTinfo) {
                const fixuserJWTinfo = JSON.parse(userJWTinfo);
                fixuserJWTinfo.AuthKey = action.payload.token;
                saveState('User', JSON.stringify(fixuserJWTinfo));
            }
            state.userInfo = JSON.stringify({
                id: action.payload.id,
                isAdmin: action.payload.isAdmin,
                dkAdmin: action.payload.isDigitalKatalog,
                firmaAdmin: action.payload.firmaAdmin,
                pazarYeriAdmin: action.payload.dkAdmin,
                firmaId: action.payload.dkFirmaid,
                UyefirmaId: action.payload.firmaId,
                dkFirmaid: action.payload.dkFirmaid,
                dkFirmaSeo:action.payload.dkFirmaSeo,
                tokenCreateDate: action.payload.tokenCreateDate,
                tokenExpireDate: action.payload.tokenExpireDate
            })
            saveState('UserInfo', state.userInfo)
        },
        deleteUserInfo: (state) => {
            state.userInfo = false
            saveState('UserInfo', state.userInfo)
        }
    },
})

export const { setUserInfo, deleteUserInfo } = UserInfoSlice.actions

export default UserInfoSlice.reducer