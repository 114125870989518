import axios from "axios"
import { loadState } from "./LocalStorage"
import { ShowMessage } from "../../Components/Common/Alerts";

const baseURL = 'https://appapi.yapiradar.com/api/'
//const baseURL = 'https://testapi.yapiradar.com/api/'
const localORIGIN = 'http://127.0.0.1:3000'
const appORIGIN = 'https://www.yapiradar.com'
const testORIGIN = 'https://test.yapiradar.com'

export const getData = async (url,) => {
    const UserData = JSON.parse(loadState('User'));
    const AuthToken = UserData.AuthKey ? UserData.AuthKey : '';
    const URL = baseURL + url;
    let res = await axios.get(URL, { headers: { 'Authorization': AuthToken, "Access-Control-Allow-Origin": appORIGIN } })
        .catch((err) => {
            if (err.response.status === 401) {
                ShowMessage('error', 'Uzun Süredir işlem yapmadığınız için Oturumunuz sonlandırılacaktır.')
                window.location.href = `/Logout`
            }
        });
    return res;
}


export const postData = async (url, data) => {
    const UserData = JSON.parse(loadState('User'));
    const AuthToken = UserData.AuthKey ? UserData.AuthKey : '';
    const URL = baseURL + url;
    let res = await axios.post(URL, data, { headers: { 'Authorization': AuthToken } })
        .catch((err) => {
            if (err.response.status === 401) {
                ShowMessage('error', 'Uzun Süredir işlem yapmadığınız için Oturumunuz sonlandırılacaktır.')
                window.location.href = `/Logout`
            }
        });
    return res;
}